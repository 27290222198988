var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "兑付记录",
        visible: _vm.recordshow,
        "append-to-body": "",
        "destroy-on-close": "",
        width: "60%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.recordshow = $event
        },
      },
    },
    [
      _c(
        "div",
        _vm._l(_vm.tabledata, function (list) {
          return _c(
            "el-card",
            { key: list.phase_id, staticStyle: { "margin-top": "5px" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-evenly",
                  },
                },
                [
                  _c("h4", [_vm._v("期数：" + _vm._s(list.title))]),
                  _c("h4", [
                    _vm._v("开始兑付：" + _vm._s(list.start_time_text)),
                  ]),
                  _c("h4", [_vm._v("已兑：" + _vm._s(list.expected))]),
                ]
              ),
              _c(
                "el-table",
                { attrs: { stripe: "", data: list.data } },
                _vm._l(_vm.Columns, function (tableItem, tableIndex) {
                  return _c("el-table-column", {
                    key: tableIndex,
                    attrs: {
                      prop: tableItem.prop,
                      label: tableItem.label,
                      width: tableItem.width,
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              tableItem.label == "商品名称"
                                ? _c("div", [
                                    _vm._v(
                                      " " + _vm._s(row.goods.goods_name) + " "
                                    ),
                                  ])
                                : _c("div", [
                                    _vm._v(
                                      " " + _vm._s(row[tableItem.prop]) + " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  })
                }),
                1
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.recordshow = false
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }