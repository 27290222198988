var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: "陈列兑付",
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "append-to-body": "",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "orderTest-container" },
        [
          _vm.form
            ? _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.form,
                    inline: "",
                    "label-position": "right",
                    "label-width": "120px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "陈列活动:", prop: "display_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.display_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "display_name", $$v)
                          },
                          expression: "form.display_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据号:", prop: "no" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.no,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "no", $$v)
                          },
                          expression: "form.no",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "兑付方:", prop: "cust_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.cust_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cust_name", $$v)
                          },
                          expression: "form.cust_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人:", prop: "cust_boss" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.cust_boss,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cust_boss", $$v)
                          },
                          expression: "form.cust_boss",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话:", prop: "cust_mobile" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.cust_mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cust_mobile", $$v)
                          },
                          expression: "form.cust_mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "地址:", prop: "cust_address" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "350px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.cust_address,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "cust_address", $$v)
                          },
                          expression: "form.cust_address",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div"),
                  _c(
                    "el-form-item",
                    { attrs: { label: "业务员:", prop: "staff_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.staff_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "staff_name", $$v)
                          },
                          expression: "form.staff_name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "日期:", prop: "date" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "210px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.date,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "date", $$v)
                          },
                          expression: "form.date",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出货总仓:", prop: "deposit_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "出货仓库" },
                          on: { change: _vm.depotChange },
                          model: {
                            value: _vm.form.deposit_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "deposit_id", $$v)
                            },
                            expression: "form.deposit_id",
                          },
                        },
                        _vm._l(_vm.depotList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "送货人:", prop: "driver_id" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "送货人" },
                          model: {
                            value: _vm.form.driver_id,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "driver_id", $$v)
                            },
                            expression: "form.driver_id",
                          },
                        },
                        _vm._l(_vm.shrList, function (i, idx) {
                          return _c("el-option", {
                            key: idx,
                            attrs: { value: i.id, label: i.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            [
              _c(
                "el-card",
                { staticStyle: { "margin-top": "5px" } },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "margin-bottom": "15px",
                      },
                    },
                    [
                      _c("div", { staticStyle: { "line-height": "31px" } }, [
                        _c("span", { staticStyle: { margin: "0 15px 0 0" } }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.tit.title) +
                              ": 承兑日期:" +
                              _vm._s(_vm.tit.time_text) +
                              " "
                          ),
                        ]),
                        _c(
                          "span",
                          { staticStyle: { "margin-right": "15px" } },
                          [
                            _vm._v(
                              " 承兑总值: " +
                                _vm._s(_vm.goods_total.total) +
                                " "
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.list } },
                    [
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            align: "center",
                            type: "index",
                            width: "50",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "header" },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    "popper-class": "custom-table-checkbox",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      model: {
                                        value: _vm.checkList,
                                        callback: function ($$v) {
                                          _vm.checkList = $$v
                                        },
                                        expression: "checkList",
                                      },
                                    },
                                    _vm._l(_vm.columns, function (item, index) {
                                      return _c("el-checkbox", {
                                        key: index,
                                        attrs: { label: item.label },
                                      })
                                    }),
                                    1
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [
                                      _c("vab-remix-icon", {
                                        attrs: { icon: "settings-line" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          align: "center",
                          type: "selection",
                          width: "50",
                        },
                      }),
                      _vm._l(
                        _vm.finallyColumns,
                        function (tableItem, tableIndex) {
                          return _c("el-table-column", {
                            key: tableIndex,
                            attrs: {
                              prop: tableItem.prop,
                              label: tableItem.label,
                              width: tableItem.width,
                              align: "center",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var row = ref.row
                                    return [
                                      tableItem.label == "商品名称"
                                        ? _c(
                                            "div",
                                            [
                                              _c("goods-search", {
                                                ref: "goodsSearch",
                                                refInFor: true,
                                                attrs: {
                                                  "f-key": row.goods_name,
                                                  search: !_vm.view,
                                                  disab: _vm.view,
                                                },
                                                on: {
                                                  "select-goods-all": function (
                                                    $event
                                                  ) {
                                                    return _vm.goodsSelect(
                                                      $event,
                                                      row
                                                    )
                                                  },
                                                  "add-rows": function (
                                                    $event
                                                  ) {
                                                    return _vm.addRows($event)
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : tableItem.label == "类型"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "类型",
                                                  },
                                                  model: {
                                                    value: row.goodsType,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "goodsType",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "row.goodsType",
                                                  },
                                                },
                                                _vm._l(
                                                  row.goods_type,
                                                  function (i, idx) {
                                                    return _c("el-option", {
                                                      key: idx,
                                                      attrs: {
                                                        value: i.id,
                                                        label: i.name,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : tableItem.label == "单位"
                                        ? _c(
                                            "div",
                                            [
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    placeholder: "单位",
                                                    disabled: _vm.view,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.unitChange(
                                                        $event,
                                                        row
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: row.min_unit_id,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        row,
                                                        "min_unit_id",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row.min_unit_id",
                                                  },
                                                },
                                                _vm._l(
                                                  row.arr_convert_unit,
                                                  function (i, idx) {
                                                    return _c("el-option", {
                                                      key: idx,
                                                      attrs: {
                                                        value: i.id,
                                                        label: i.unit_name,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : tableItem.label == "数量"
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                attrs: {
                                                  type: "number",
                                                  min: 0,
                                                },
                                                model: {
                                                  value:
                                                    row.total_min_convert_number,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      row,
                                                      "total_min_convert_number",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "row.total_min_convert_number",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : tableItem.label == "金额"
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (
                                                    row.goods_price *
                                                    row.total_min_convert_number
                                                  ).toFixed(2)
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : tableItem.label == "备注"
                                        ? _c(
                                            "div",
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: row.remark,
                                                  callback: function ($$v) {
                                                    _vm.$set(row, "remark", $$v)
                                                  },
                                                  expression: "row.remark",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(row[tableItem.prop]) +
                                                " "
                                            ),
                                          ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          })
                        }
                      ),
                      !_vm.view
                        ? _c("el-table-column", {
                            attrs: {
                              prop: "",
                              align: "center",
                              label: "操作",
                              fixed: "right",
                              "min-width": "110px",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (ref) {
                                    var $index = ref.$index
                                    var row = ref.row
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleCopyRow(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 复制 ")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          nativeOn: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.handleDeleteRow(
                                                $index,
                                                row
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" 删除 ")]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              394107111
                            ),
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "space-between",
                        "margin-top": "15px",
                      },
                    },
                    [
                      _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "5px" } },
                            [_vm._v("兑付现金:")]
                          ),
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { disabled: _vm.view },
                            model: {
                              value: _vm.tit.replace_amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.tit, "replace_amount", $$v)
                              },
                              expression: "tit.replace_amount",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            "span",
                            { staticStyle: { "margin-right": "5px" } },
                            [_vm._v("备注:")]
                          ),
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: { disabled: _vm.view },
                            model: {
                              value: _vm.tit.remark,
                              callback: function ($$v) {
                                _vm.$set(_vm.tit, "remark", $$v)
                              },
                              expression: "tit.remark",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
            [_vm._v("提 交")]
          ),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }