import request from '@/utils/request'

// 陈列协议查询列表
export function getList(data) {
  return request({
    url: '/promoteAdmin/display/participated-list',
    method: 'post',
    data,
  })
}
// 状态下拉选项
export function statusList(data) {
  return request({
    url: '/promoteAdmin/display/participated-option',
    method: 'post',
    data,
  })
}

// 陈列协议详情
export function getDetail(data) {
  return request({
    url: '/promoteAdmin/display/participated-view',
    method: 'post',
    data,
  })
}

// 某期兑付详情
export function someDetail(data) {
  return request({
    url: '/promoteAdmin/display/participated-cashing-view',
    method: 'post',
    data,
  })
}

// 按期兑付
export function smDetail(data) {
  return request({
    url: '/promoteAdmin/display/participated-cashing',
    method: 'post',
    data,
  })
}

// 手动结束兑付 某期活动
export function duifuFun(data) {
  return request({
    url: '/promoteAdmin/display/participated-is-end',
    method: 'post',
    data,
  })
}
