var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1000px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
          staticStyle: { "min-height": "800px" },
        },
        [
          _vm.form
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        inline: "",
                        "label-position": "right",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "活动名称:", prop: "display_name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.display_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "display_name", $$v)
                              },
                              expression: "form.display_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单据号:", prop: "no" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.no,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "no", $$v)
                              },
                              expression: "form.no",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "客户名称:", prop: "cust_name" } },
                        [
                          _c("client-search", {
                            ref: "clientSearch",
                            staticStyle: { width: "210px" },
                            attrs: {
                              disab: true,
                              search: !_vm.view,
                              keyword: _vm.form.cust_name,
                              "popper-class": "selectDC",
                            },
                            on: { "select-val-alldata": _vm.clientSelect },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人:", prop: "cust_boss" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.lxr,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "lxr", $$v)
                              },
                              expression: "form.lxr",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "地址:", prop: "cust_address" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "550px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.cust_address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cust_address", $$v)
                              },
                              expression: "form.cust_address",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div"),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "参加时间:", prop: "create_at_text" },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.create_at_text,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "create_at_text", $$v)
                              },
                              expression: "form.create_at_text",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "业务员:", prop: "create_at_text" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "210px" },
                              attrs: {
                                clearable: "",
                                placeholder: "请选择业务员",
                                disabled: "",
                              },
                              model: {
                                value: _vm.form.staff_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "staff_id", $$v)
                                },
                                expression: "form.staff_id",
                              },
                            },
                            _vm._l(_vm.staffList, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "陈列起止日期:", prop: "time" } },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "210px" },
                            attrs: {
                              type: "daterange",
                              "range-separator": "-",
                              "value-format": "yyyy-MM-dd",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              disabled: _vm.view,
                            },
                            model: {
                              value: _vm.time,
                              callback: function ($$v) {
                                _vm.time = $$v
                              },
                              expression: "time",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态:", prop: "step_status_text" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.step_status_text,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "step_status_text", $$v)
                              },
                              expression: "form.step_status_text",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "陈列天数:", prop: "show_day" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "210px" },
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.form.show_day,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "show_day", $$v)
                              },
                              expression: "form.show_day",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注:", prop: "display_content" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "550px" },
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.form.display_content,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "display_content", $$v)
                              },
                              expression: "form.display_content",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    { attrs: { stripe: "", data: _vm.form.modelgoods } },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "goods_name",
                          label: "陈列商品",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "display_type_text",
                          label: "陈列类型",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "number",
                          label: "数量",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "unit_name",
                          label: "单位",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "remark",
                          label: "备注",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._l(_vm.form.customercashphase, function (item, index) {
                    return _c(
                      "div",
                      { key: index },
                      [
                        _c(
                          "el-card",
                          { staticStyle: { "margin-top": "5px" } },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "margin-bottom": "15px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticStyle: { "line-height": "31px" } },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { margin: "0 15px 0 0" } },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(item.title) +
                                            ": 承兑日期:" +
                                            _vm._s(item.time_text) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "15px" },
                                      },
                                      [
                                        _vm._v(
                                          " 承兑总值: " +
                                            _vm._s(item.expect) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      " 已兑付: " + _vm._s(item.expected) + " "
                                    ),
                                    item.is_end == 3 &&
                                    item.step_status != 1 &&
                                    item.step_status != 20
                                      ? _c("span")
                                      : _c("span", [
                                          _vm._v(
                                            "兑付时间:" + _vm._s(item.time)
                                          ),
                                        ]),
                                  ]
                                ),
                                _c("div", [
                                  item.is_end == 3 &&
                                  item.step_status != 1 &&
                                  item.step_status != 20
                                    ? _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showBtn,
                                                  expression: "showBtn",
                                                },
                                              ],
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.duifu(item.id)
                                                },
                                              },
                                            },
                                            [_vm._v(" 兑付 ")]
                                          ),
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showBtn,
                                                  expression: "showBtn",
                                                },
                                              ],
                                              attrs: { type: "warning" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.wanchengduifu(
                                                    item.expect,
                                                    item.expected,
                                                    item.id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" 完成兑付 ")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "span",
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.showBtn,
                                                  expression: "showBtn",
                                                },
                                              ],
                                              attrs: { disabled: "" },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.is_end_text) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                ]),
                              ]
                            ),
                            _c(
                              "el-table",
                              { attrs: { stripe: "", data: item.goods_info } },
                              [
                                _vm._l(
                                  _vm.finallyColumns,
                                  function (tableItem, tableIndex) {
                                    return _c("el-table-column", {
                                      key: tableIndex,
                                      attrs: {
                                        prop: tableItem.prop,
                                        label: tableItem.label,
                                        width: tableItem.width,
                                        align: "center",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var row = ref.row
                                              return [
                                                tableItem.label == "兑付商品"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("goods-search", {
                                                          ref: "goodsSearch",
                                                          refInFor: true,
                                                          attrs: {
                                                            "f-key":
                                                              row.goods_name,
                                                            search: !_vm.view,
                                                            disab: _vm.view,
                                                          },
                                                          on: {
                                                            "select-goods-all":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.goodsSelect(
                                                                  $event,
                                                                  row,
                                                                  index
                                                                )
                                                              },
                                                            "add-rows":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.addRows(
                                                                  $event,
                                                                  index
                                                                )
                                                              },
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : tableItem.label == "单位"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          "el-select",
                                                          {
                                                            attrs: {
                                                              placeholder:
                                                                "单位",
                                                              disabled:
                                                                row.log_status ==
                                                                  1 || _vm.view,
                                                            },
                                                            on: {
                                                              change: function (
                                                                $event
                                                              ) {
                                                                return _vm.unitChange(
                                                                  $event,
                                                                  row
                                                                )
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                row.unit_id,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    row,
                                                                    "unit_id",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "row.unit_id",
                                                            },
                                                          },
                                                          _vm._l(
                                                            row.arr_unit,
                                                            function (i, idx) {
                                                              return _c(
                                                                "el-option",
                                                                {
                                                                  key: idx,
                                                                  attrs: {
                                                                    value: i.id,
                                                                    label:
                                                                      i.unit_name,
                                                                  },
                                                                }
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : tableItem.label == "数量"
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c("el-input", {
                                                          attrs: {
                                                            type: "number",
                                                            disabled:
                                                              _vm.view ||
                                                              row.log_status ==
                                                                1,
                                                            min: 0,
                                                          },
                                                          model: {
                                                            value: row.number,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                row,
                                                                "number",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "row.number",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    )
                                                  : tableItem.label == "金额" &&
                                                    !_vm.view
                                                  ? _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            Number(
                                                              (
                                                                row.goods_price *
                                                                row.number
                                                              ).toFixed(2)
                                                            )
                                                          ) +
                                                          " "
                                                      ),
                                                    ])
                                                  : _c("div", [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            row[tableItem.prop]
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  }
                                ),
                                !_vm.view && item.log_status == 0
                                  ? _c("el-table-column", {
                                      attrs: {
                                        prop: "",
                                        align: "center",
                                        label: "操作",
                                        "min-width": "110px",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (ref) {
                                              var $index = ref.$index
                                              var row = ref.row
                                              return [
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.handleCopyRow(
                                                          $index,
                                                          row,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 复制 ")]
                                                ),
                                                _c(
                                                  "el-button",
                                                  {
                                                    attrs: { type: "text" },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.handleDeleteRow(
                                                          $index,
                                                          row,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" 删除 ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              2
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "justify-content": "space-between",
                                  "margin-top": "15px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [_vm._v("兑付现金:")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "200px" },
                                      attrs: {
                                        disabled:
                                          _vm.view || item.log_status == 1,
                                      },
                                      model: {
                                        value: item.converted_total_amount,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            item,
                                            "converted_total_amount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "item.converted_total_amount",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                      },
                                      [_vm._v("备注:")]
                                    ),
                                    _c("el-input", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        disabled:
                                          _vm.view || item.log_status == 1,
                                      },
                                      model: {
                                        value: item.remark,
                                        callback: function ($$v) {
                                          _vm.$set(item, "remark", $$v)
                                        },
                                        expression: "item.remark",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.form,
                        "label-position": "right",
                        "label-width": "90px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "陈列照片", prop: "" } },
                        [
                          _c(
                            "div",
                            { staticClass: "img-wrap" },
                            _vm._l(_vm.imgList, function (img, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: { "margin-right": "10px" },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "180px",
                                      height: "180px",
                                    },
                                    attrs: {
                                      src: img.file,
                                      "preview-src-list": _vm.imgListBig,
                                    },
                                  }),
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [_vm._v(_vm._s(img.title))]
                                  ),
                                ],
                                1
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          !_vm.view
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
          _c("record", { ref: "record" }),
          _c("el-button", { on: { click: _vm.handlerrecord } }, [
            _vm._v("兑付记录"),
          ]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关 闭")]),
        ],
        1
      ),
      _c("duifu", { ref: "duifu", on: { refresh: _vm.fetchData } }),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: "确定完成本期兑付？",
            visible: _vm.showDialog2,
            width: "500px",
            top: "20vh",
            center: "center",
            "close-on-click-modal": false,
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog2 = $event
            },
          },
        },
        [
          _c("div", { staticClass: "orderTest-container" }, [
            _c(
              "div",
              { staticStyle: { margin: "0 auto", "text-align": "center" } },
              [
                _c("div", { staticStyle: { margin: "0 0 15px 0" } }, [
                  _vm._v("本期承兑总值:" + _vm._s(_vm.a)),
                ]),
                _c("div", [_vm._v("实际兑付总值:" + _vm._s(_vm.b))]),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "5px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubmit } },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog2 = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }