<template>
  <el-dialog
    :modal="false"
    title="陈列兑付"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    append-to-body
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div class="orderTest-container">
      <el-form
        v-if="form"
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="120px"
      >
        <el-form-item label="陈列活动:" prop="display_name">
          <el-input
            v-model="form.display_name"
            style="width: 210px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="单据号:" prop="no">
          <el-input v-model="form.no" style="width: 210px" disabled></el-input>
        </el-form-item>
        <el-form-item label="兑付方:" prop="cust_name">
          <el-input
            v-model="form.cust_name"
            style="width: 210px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人:" prop="cust_boss">
          <el-input
            v-model="form.cust_boss"
            style="width: 210px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="电话:" prop="cust_mobile">
          <el-input
            v-model="form.cust_mobile"
            style="width: 210px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="地址:" prop="cust_address">
          <el-input
            v-model="form.cust_address"
            style="width: 350px"
            disabled
          ></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="业务员:" prop="staff_name">
          <el-input
            v-model="form.staff_name"
            style="width: 210px"
            disabled
          ></el-input>
        </el-form-item>
        <el-form-item label="日期:" prop="date">
          <el-input
            v-model="form.date"
            style="width: 210px"
            disabled
          ></el-input>
        </el-form-item>

        <el-form-item label="出货总仓:" prop="deposit_id">
          <el-select
            v-model="form.deposit_id"
            clearable
            placeholder="出货仓库"
            @change="depotChange"
          >
            <el-option
              v-for="(i, idx) in depotList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="送货人:" prop="driver_id">
          <el-select v-model="form.driver_id" clearable placeholder="送货人">
            <el-option
              v-for="(i, idx) in shrList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <el-card style="margin-top: 5px">
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-bottom: 15px;
            "
          >
            <div style="line-height: 31px">
              <span style="margin: 0 15px 0 0">
                {{ tit.title }}: 承兑日期:{{ tit.time_text }}
              </span>
              <span style="margin-right: 15px">
                承兑总值: {{ goods_total.total }}
              </span>
            </div>
          </div>
          <el-table stripe :data="list">
            <!-- 序号 -->
            <el-table-column align="center" type="index" width="50">
              <template slot="header">
                <el-popover
                  popper-class="custom-table-checkbox"
                  trigger="hover"
                >
                  <el-checkbox-group v-model="checkList">
                    <el-checkbox
                      v-for="(item, index) in columns"
                      :key="index"
                      :label="item.label"
                    ></el-checkbox>
                  </el-checkbox-group>
                  <el-button slot="reference" type="text">
                    <vab-remix-icon icon="settings-line" />
                  </el-button>
                </el-popover>
              </template>
            </el-table-column>
            <!-- 选择框 -->
            <el-table-column
              align="center"
              type="selection"
              width="50"
            ></el-table-column>
            <el-table-column
              v-for="(tableItem, tableIndex) in finallyColumns"
              :key="tableIndex"
              :prop="tableItem.prop"
              :label="tableItem.label"
              :width="tableItem.width"
              align="center"
            >
              <template #default="{ row }">
                <div v-if="tableItem.label == '商品名称'">
                  <goods-search
                    ref="goodsSearch"
                    :f-key="row.goods_name"
                    :search="!view"
                    :disab="view"
                    @select-goods-all="goodsSelect($event, row)"
                    @add-rows="addRows($event)"
                  ></goods-search>
                </div>
                <div v-else-if="tableItem.label == '类型'">
                  <el-select v-model="row.goodsType" placeholder="类型">
                    <el-option
                      v-for="(i, idx) in row.goods_type"
                      :key="idx"
                      :value="i.id"
                      :label="i.name"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else-if="tableItem.label == '单位'">
                  <el-select
                    v-model="row.min_unit_id"
                    placeholder="单位"
                    :disabled="view"
                    @change="unitChange($event, row)"
                  >
                    <el-option
                      v-for="(i, idx) in row.arr_convert_unit"
                      :key="idx"
                      :value="i.id"
                      :label="i.unit_name"
                    ></el-option>
                  </el-select>
                </div>
                <div v-else-if="tableItem.label == '数量'">
                  <el-input
                    v-model="row.total_min_convert_number"
                    type="number"
                    :min="0"
                  ></el-input>
                </div>
                <div v-else-if="tableItem.label == '金额'">
                  {{
                    (row.goods_price * row.total_min_convert_number).toFixed(2)
                  }}
                </div>
                <div v-else-if="tableItem.label == '备注'">
                  <el-input v-model="row.remark"></el-input>
                </div>
                <div v-else>
                  {{ row[tableItem.prop] }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="!view"
              prop=""
              align="center"
              label="操作"
              fixed="right"
              min-width="110px"
            >
              <template #default="{ $index, row }">
                <el-button
                  type="text"
                  @click.native.prevent="handleCopyRow($index, row)"
                >
                  复制
                </el-button>
                <el-button
                  type="text"
                  @click.native.prevent="handleDeleteRow($index, row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div
            style="
              display: flex;
              justify-content: space-between;
              margin-top: 15px;
            "
          >
            <div>
              <span style="margin-right: 5px">兑付现金:</span>
              <el-input
                v-model="tit.replace_amount"
                :disabled="view"
                style="width: 200px"
              ></el-input>
            </div>
            <div>
              <span style="margin-right: 5px">备注:</span>
              <el-input
                v-model="tit.remark"
                style="width: 300px"
                :disabled="view"
              ></el-input>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <!-- 底部按钮 -->
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button type="primary" @click="handleSubmit">提 交</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import GoodsSearch from '@/baseComponents/goodsSearch'
  import { someDetail, smDetail } from '@/api/payListQuery'
  import { driverList, getAllSelect } from '@/api/advanceOrder'
  export default {
    name: '',
    components: { GoodsSearch },
    data() {
      return {
        id: '',
        showDialog: false,
        view: false,
        shrList: [],
        depotList: [],
        form: '',
        tit: {},
        list: [],
        goods_total: {},
        checkList: ['类型', '商品名称', '单位', '单价', '数量', '金额', '备注'],
        columns: [
          {
            order: 1,
            label: '类型',
            prop: '',
            width: '100px',
          },
          {
            order: 2,
            label: '商品名称',
            prop: '',
            width: '180px',
          },
          {
            order: 3,
            label: '单位',
            prop: '100px',
            width: '',
          },
          {
            order: 4,
            label: '单价',
            prop: 'goods_price',
            width: '90px',
          },
          {
            order: 5,
            label: '数量',
            prop: '',
            width: '100px',
          },
          {
            order: 6,
            label: '金额',
            prop: '90px',
            width: '',
          },
          {
            order: 7,
            label: '备注',
            prop: 'remark',
            width: '200px',
          },
        ],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      showDialog(val) {
        if (val) {
          this.fetchData()
        }
      },
    },
    created() {
      // 出货仓库下拉
      getAllSelect().then((res) => {
        if (res.code == 200) {
          this.depotList = res.data.depot
        }
      })
    },
    mounted() {},
    methods: {
      depotChange(id) {
        if (id) {
          this.shr(id)
        } else {
          this.form.driver_id = ''
          this.shrList = []
        }
      },
      shr(id) {
        // 送货人下拉
        driverList({ depot_id: id }).then((res) => {
          if (res.code == 200) {
            this.shrList = res.data
          }
        })
      },
      async fetchData() {
        console.log(this.id)
        let { data, msg, code } = await someDetail({ d_phase_id: this.id })
        console.log(data)
        this.form = data.info
        this.tit = data.phase_info
        this.goods_total = data.goods_total
        this.list = data.goods_info
        this.list.forEach((item) => {
          item.goodsType = 4
        })
        this.addRow()
      },
      obj() {
        return {
          new: true,
          goods_amount: '',
          goods_id: '',
          goods_name: '',
          str_d_goods_ids: '',
          goods_price: '0',
          total_min_convert_number: 0,
          goodsType: 4,
          goods_type: [{ id: 4, name: '兑付' }],
          remark: '',
          min_unit_id: '',
          specs: '',
          total_min_unit_name: '',
        }
      },
      addRow() {
        this.list.push(this.obj())
      },
      close() {
        this.showDialog = false
      },
      goodsSelect(val, row) {
        console.log(val)
        row.goods_id = val.goods_id
        row.min_unit_id = ''
        row.arr_convert_unit = val.arr_unit
        row.goods_price = 0
        row.total_min_convert_number = 0
        if (this.list[this.list.length - 1].goods_id !== '') {
          this.addRow()
        }
      },
      addRows(val) {
        val.forEach((item) => {
          item.total_min_convert_number = 0
          item.arr_convert_unit = item.arr_unit
          item.goodsType = 4
          item.goods_type = [
            {
              id: 4,
              name: '兑付',
            },
          ]
          this.list.push(item)
        })
      },
      // 单位变换
      unitChange(val, row) {
        if (row.new) {
          let a = row.arr_convert_unit.filter((item) => item.id == val)[0]
            .purc_price
          console.log(a)
          if (a == 0) {
            row.goods_price = row.arr_convert_unit.filter(
              (item) => item.id == val
            )[0].sell_price
          } else {
            row.goods_price = a
          }
        } else {
          row.goods_price = row.arr_convert_unit.filter(
            (item) => item.id == val
          )[0].goods_price
        }
      },
      handleCopyRow(index, row) {
        this.list.splice(index, 0, JSON.parse(JSON.stringify(row)))
      },
      handleDeleteRow(index, row) {
        if (row.goods_id == '') {
          this.$message.error('不能删除此行')
          return false
        }
        if (this.list.length == 1) {
          this.$message.error('不能删除最后一行')
        } else {
          this.list.splice(index, 1)
        }
      },
      handleSubmit() {
        let phase_info = {
          d_phase_id: this.id,
          replace_amount: this.tit.replace_amount,
          remark: this.tit.remark,
        }
        let order_info = {
          deposit_id: this.form.deposit_id,
          driver_id: this.form.driver_id,
        }
        let list = JSON.parse(JSON.stringify(this.list))
        let goods_info = []

        list.forEach((item, index) => {
          if (item.goods_id == '') {
            list.splice(index, 1)
          } else {
            goods_info.push({
              str_d_goods_ids: item.str_d_goods_ids,
              goods_id: item.goods_id,
              arr_convert_unit: [
                {
                  goods_id: item.goods_id,
                  id: item.min_unit_id,
                  num: item.total_min_convert_number,
                  remark: item.remark,
                },
              ],
            })
          }
        })
        let end = {
          phase_info: JSON.stringify(phase_info),
          order_info: JSON.stringify(order_info),
          goods_info: JSON.stringify(goods_info),
        }
        console.log(end)
        smDetail(end).then((res) => {
          this.$message(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
